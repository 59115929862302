import { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Addacgroup.css';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import { fetchCars } from '../redux/cars/carSlice';
import { useToken } from './auth';
import InfoIcon from '@mui/icons-material/Info';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router';
import moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';
import writeXlsxFile from 'write-excel-file';

export default function RefundDisplay() {
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  //const user = useUser();
  const dispatch = useDispatch();
  const [locations, setLocations] = useState([]);
  const [locationSearch, setLocationSearch] = useState(0);
  const [savedRefunds, setSavedRefunds] = useState([]);
  const [searchMessagee, setSearchMessage] = useState('');
  useEffect(() => {
    async function LocationList() {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/signup/locations`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setLocations(data);
      } catch (error) {
        console.log(error);
      }
    }
    LocationList();
  }, [token, url]);

  function handSaerchLocation(event) {
    setLocationSearch(event.target.value);
  }

  //getting saved refund
  async function savedRefund(time) {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${url}/finance/saved-refund?datee=${time}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      };

      const { data } = await axios.request(config);
      setSavedRefunds(data.newDatas);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const time = moment().format().substring(0, 10);
    async function savedRefund(time) {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/finance/saved-refund?datee=${time}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setSavedRefunds(data.newDatas);
      } catch (error) {
        console.log(error);
      }
    }
    savedRefund(time);
  }, [token, url]);
  //end of of saved refund

  useEffect(() => {
    dispatch(fetchCars(token));
  }, [dispatch, token]);
  // Getting existing users
  const [garageDataa, setGarageDataa] = useState([]);
  useEffect(() => {
    const userData = async () => {
      const time = moment().format().substring(0, 10);
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/refund-data?sdate=${time}&edate=${time}&location=${locationSearch}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setGarageDataa(data);
      } catch (error) {
        console.log(error);
      }
    };
    userData();
  }, [token, url, locationSearch]);

  // searching

  async function search() {
    setGarageDataa([]);
    const sdate = document.querySelector('#s-datee').value;
    savedRefund(sdate);
    if (sdate === '') {
      setSearchMessage('Please select start date and end date');
      document.querySelector('#searchMessagee').style.color = 'red';
    } else {
      setSearchMessage('Tegereza.....');
      document.querySelector('#searchMessagee').style.color = 'blue';

      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/refund-data?sdate=${sdate}&edate=${sdate}&location=${locationSearch}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setGarageDataa(data);
        if (data.rufundDatas.length !== 0) {
          setSearchMessage('');
        } else {
          setSearchMessage(`No data found for this date: ${sdate} `);
          document.querySelector('#searchMessagee').style.color = 'green';
        }
      } catch (error) {
        console.log(error);
        setSearchMessage('Error while retrieving data');
        document.querySelector('#searchMessagee').style.color = 'red';
      }
    }
  }

  //making new data
  let newDatas = [];
  let done = [];

  if (Object.keys(garageDataa).length !== 0) {
    garageDataa.rufundDatas.forEach((row) => {
      if (!done.includes(row.location) && row.dtype === 'other') {
        let carAmt = 0;
        let refundAmt = 0;
        garageDataa.rufundDatas.forEach((element) => {
          if (element.dtype === 'car' && element.location === row.location) {
            carAmt = element.amt;
          }
        });
        refundAmt = carAmt + row.amt;
        done.push(row.location);
        newDatas.push({
          userdata: row.userdata,
          user: row.user,
          car: carAmt,
          other: row.amt,
          refundAmt,
          location: row.location,
          location_name: row.location_name,
          time: row.time,
        });
      } else if (!done.includes(row.location) && row.dtype === 'car') {
        let oAmt = 0;
        let refundAmt = 0;
        garageDataa.rufundDatas.forEach((element) => {
          if (element.dtype === 'other' && element.location === row.location) {
            oAmt = element.amt;
          }
        });
        refundAmt = oAmt + row.amt;
        done.push(row.location);
        newDatas.push({
          userdata: row.userdata,
          user: row.user,
          car: row.amt,
          other: oAmt,
          refundAmt,
          location: row.location,
          location_name: row.location_name,
          time: row.time,
        });
      }
    });
  }
  //totals

  let totalCar = 0;
  let totalOther = 0;
  let totalRefund = 0;
  let displayData = [];

  if (Object.keys(newDatas).length !== 0) {
    let i = 0;
    displayData = newDatas.map((row) => {
      i++;
      totalCar += parseInt(row.car);
      totalOther += parseInt(row.other);
      totalRefund += parseInt(row.refundAmt);
      let saved = 'no';
      if (savedRefunds.includes(row.location)) {
        saved = 'yes';
      }
      return { ...row, i, saved };
    });
  }
  //console.log(savedRefunds);
  // preparing data for export

  const HEADER_ROW = [
    {
      value: 'No',
      fontWeight: 'bold',
    },
    {
      value: 'User',
      fontWeight: 'bold',
    },

    {
      value: "Car' expenses",
      fontWeight: 'bold',
    },

    {
      value: 'Other expenses',
      fontWeight: 'bold',
    },
    {
      value: 'Refund Amount',
      fontWeight: 'bold',
    },
    {
      value: 'Location',
      fontWeight: 'bold',
    },
  ];
  //datas
  let rowss = [];
  rowss = displayData.map((row) => {
    return [
      {
        type: Number,
        value: row.i,
      },
      {
        value: row.userdata,
      },
      {
        value: row.car,
      },
      {
        value: row.other,
      },
      {
        value: row.refundAmt,
      },
      {
        value: row.location_name,
      },
    ];
  });
  //totals
  const TOTAL_ROW = [
    {
      value: 'TOTAL',
      fontWeight: 'bold',
    },

    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: totalCar,
      fontWeight: 'bold',
    },
    {
      value: totalOther,
      fontWeight: 'bold',
    },
    {
      value: totalRefund,
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
  ];

  const data = [HEADER_ROW, ...rowss, TOTAL_ROW];
  async function exportData(d) {
    await writeXlsxFile(d, {
      fileName: 'Refund.xlsx',
    });
  }

  // Cash display

  return (
    <div>
      <div className="cars-add-btn-dv">
        <FormControl sx={{ m: 1, width: '20%', marginTop: 2.5 }}>
          <InputLabel id="demo-simple-select-label">Location</InputLabel>
          <Select
            id="location_search"
            label="Location*"
            value={locationSearch}
            onChange={handSaerchLocation}
          >
            <MenuItem key={0} value={0}>
              All locations
            </MenuItem>
            {locations.length !== 0 &&
              locations.locationLists.map((loc) => (
                <MenuItem key={loc.id} value={loc.id}>
                  {loc.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <input
          style={{ height: '40px', marginTop: '30px' }}
          name="s-datee"
          id="s-datee"
          type="date"
        />
        <span style={{ width: '30px' }}> </span>

        <span style={{ width: '10px' }}> </span>
        <Button
          style={{ height: '40px', marginTop: '30px' }}
          onClick={search}
          variant="contained"
        >
          SEARCH
        </Button>
      </div>
      <div style={{ marginLeft: '20px' }}>
        <p id="searchMessagee">{searchMessagee}</p>
      </div>

      {Object.keys(garageDataa).length !== 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead style={{ background: 'black' }}>
              <TableRow className="">
                <TableCell>
                  <h6 style={{ color: '#fff' }}>#No</h6>
                </TableCell>
                <TableCell>
                  <h6 style={{ color: '#fff' }}>User</h6>
                </TableCell>

                <TableCell>
                  {' '}
                  <h6 style={{ color: '#fff' }}>Car's expenses</h6>
                </TableCell>
                <TableCell>
                  <h6 style={{ color: '#fff' }}>Other expenses</h6>
                </TableCell>

                <TableCell>
                  <h6 style={{ color: '#fff' }}>Refund Amount</h6>
                </TableCell>
                <TableCell>
                  <h6 style={{ color: '#fff' }}>Location</h6>
                </TableCell>
                <TableCell>
                  <h6 style={{ color: '#fff' }}>Actions</h6>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {displayData.map((row) => (
                <TableRow
                  key={row.i}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.i}
                  </TableCell>
                  <TableCell>{row.userdata}</TableCell>
                  <TableCell>{row.car}</TableCell>
                  <TableCell>{row.other}</TableCell>
                  <TableCell>{row.refundAmt}</TableCell>
                  <TableCell>{row.location_name}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        navigate('/add-refund', { state: { row } })
                      }
                      startIcon={<InfoIcon />}
                    >
                      {row.saved === 'yes' && (
                        <span style={{ color: 'green' }}> Refunded</span>
                      )}
                      {row.saved === 'no' && <span>Refund</span>}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {new Intl.NumberFormat().format(totalCar)}
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat().format(totalOther)}
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat().format(totalRefund)}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              {Object.keys(garageDataa).length === 0 && <p>Loading........</p>}
            </TableBody>
          </Table>
          <Button
            variant="outlined"
            onClick={() => exportData(data)}
            startIcon={<GetAppIcon />}
          >
            Export data
          </Button>
        </TableContainer>
      )}
    </div>
  );
}

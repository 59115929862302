import { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Addacgroup.css';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import { fetchCars } from '../redux/cars/carSlice';
import { useToken } from '../components/auth';
import moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';
import writeXlsxFile from 'write-excel-file';

export default function PetitCashDisplay() {
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const dispatch = useDispatch();
  const [searchMessage, setSearchMessage] = useState('');
  useEffect(() => {
    dispatch(fetchCars(token));
  }, [dispatch, token]);
  // Getting existing users
  const [garageDataa, setGarageDataa] = useState([]);
  useEffect(() => {
    const userData = async () => {
      const time = moment().format().substring(0, 10);
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/petit-cash-report?sdate=${time}&edate=${time}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setGarageDataa(data);
      } catch (error) {
        console.log(error);
      }
    };
    userData();
  }, [token, url]);

  // searching

  async function search() {
    setGarageDataa([]);
    const sdate = document.querySelector('#s-date').value;
    const edate = document.querySelector('#e-date').value;
    if (sdate === '' || edate === '') {
      setSearchMessage('Please select start date and end date');
      document.querySelector('#searchMessage').style.color = 'red';
    } else {
      setSearchMessage('Tegereza.....');
      document.querySelector('#searchMessage').style.color = 'blue';

      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/petit-cash-report?sdate=${sdate}&edate=${edate}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setGarageDataa(data);
        if (data.report.length !== 0) {
          setSearchMessage('');
        } else {
          setSearchMessage(`No data found from ${sdate} to ${edate}`);
          document.querySelector('#searchMessage').style.color = 'green';
        }
      } catch (error) {
        console.log(error);
        setSearchMessage('Error while retrieving data');
        document.querySelector('#searchMessage').style.color = 'red';
      }
    }
  }

  //totals

  let petit_cash = 0;
  let cars = 0;
  let others = 0;
  // let amazi = 0;
  // let ration = 0;
  // let umuriro = 0;
  let balance = 0;
  let displayData = [];

  if (Object.keys(garageDataa).length !== 0) {
    let i = 0;
    let bal = 0;
    displayData = garageDataa.report.map((row) => {
      i++;

      let ball =
        parseInt(row.petit_cash) - (parseInt(row.cars) + parseInt(row.others));
      bal += ball;
      petit_cash += parseInt(row.petit_cash);
      cars += parseInt(row.cars);
      others += parseInt(row.others);
      // amazi += parseInt(row.amazi);
      // ration += parseInt(row.ration);
      // umuriro += parseInt(row.umuriro);
      balance = parseInt(bal);

      const ti = row.time
        .toLocaleString('en-GB', {
          timeZone: 'Africa/Kigali',
        })
        .substring(0, 10);

      return { ...row, time: ti, i, bal };
    });
  }
  // preparing data for export

  const HEADER_ROW = [
    {
      value: 'DATE',
      fontWeight: 'bold',
    },

    {
      value: 'ENTRY',
      fontWeight: 'bold',
    },
    {
      value: 'OUT(Cars)',
      fontWeight: 'bold',
    },
    {
      value: 'OUT(Others)',
      fontWeight: 'bold',
    },
    {
      value: 'BALANCE',
      fontWeight: 'bold',
    },
  ];
  //datas
  let rowss = [];
  rowss = displayData.map((row) => {
    return [
      {
        type: Number,
        value: row.i,
      },
      {
        value: row.time,
      },
      {
        value: row.petit_cash,
      },
      {
        value: row.cars,
      },
      {
        value: row.others,
      },

      {
        value: row.bal,
      },
    ];
  });
  //totals
  const TOTAL_ROW = [
    {
      value: 'TOTAL',
      fontWeight: 'bold',
    },

    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: petit_cash,
      fontWeight: 'bold',
    },
    {
      value: cars,
      fontWeight: 'bold',
    },
    {
      value: others,
      fontWeight: 'bold',
    },
    {
      value: balance,
      fontWeight: 'bold',
    },
  ];

  const data = [HEADER_ROW, ...rowss, TOTAL_ROW];
  async function exportData(d) {
    await writeXlsxFile(d, {
      fileName: 'Petit-cash-Report.xlsx',
    });
  }

  // Cash display

  return (
    <div>
      <div className="cars-add-btn-dv">
        <input
          style={{ height: '40px', marginTop: '30px' }}
          name="s-date"
          id="s-date"
          type="date"
        />
        <span style={{ width: '30px' }}> </span>
        <input
          style={{ height: '40px', marginTop: '30px' }}
          name="e-date"
          id="e-date"
          type="date"
        />
        <span style={{ width: '10px' }}> </span>
        <Button
          style={{ height: '40px', marginTop: '30px' }}
          onClick={search}
          variant="contained"
        >
          SEARCH
        </Button>
      </div>
      <div style={{ marginLeft: '20px' }}>
        <p id="searchMessage">{searchMessage}</p>
      </div>

      {Object.keys(garageDataa).length !== 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead style={{ background: 'black' }}>
              <TableRow className="">
                <TableCell>
                  <h6 style={{ color: '#fff' }}>No:#</h6>
                </TableCell>
                <TableCell>
                  <h6 style={{ color: '#fff' }}>DATE</h6>
                </TableCell>

                <TableCell>
                  {' '}
                  <h6 style={{ color: '#fff' }}>ENTRY</h6>
                </TableCell>
                <TableCell>
                  <h6 style={{ color: '#fff' }}>OUT(Cars)</h6>
                </TableCell>

                <TableCell>
                  <h6 style={{ color: '#fff' }}>OUT(Others)</h6>
                </TableCell>
                <TableCell>
                  <h6 style={{ color: '#fff' }}>BALANCE</h6>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {displayData.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.i}
                  </TableCell>
                  <TableCell>{row.time}</TableCell>
                  <TableCell>{row.petit_cash}</TableCell>
                  <TableCell>{row.cars}</TableCell>
                  <TableCell>{row.others}</TableCell>
                  <TableCell>{row.bal}</TableCell>
                </TableRow>
              ))}
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {new Intl.NumberFormat().format(petit_cash)}
                </TableCell>
                <TableCell>{new Intl.NumberFormat().format(cars)}</TableCell>
                <TableCell>{new Intl.NumberFormat().format(others)}</TableCell>
                <TableCell>{new Intl.NumberFormat().format(balance)}</TableCell>
              </TableRow>
              {Object.keys(garageDataa).length === 0 && <p>Loading........</p>}
            </TableBody>
          </Table>
          <Button
            variant="outlined"
            onClick={() => exportData(data)}
            startIcon={<GetAppIcon />}
          >
            Export data
          </Button>
        </TableContainer>
      )}
    </div>
  );
}

import { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Addacgroup.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useUser, useToken } from './auth';

export default function AddCash() {
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const user = useUser();
  const { id } = user;
  const [message, setMessage] = useState('');

  const [closeRepDataa, setCloseRepDataa] = useState('');

  useEffect(() => {
    const closeRepData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/lastpetitcash`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setCloseRepDataa(data);
      } catch (error) {
        console.log(error);
      }
    };
    closeRepData();
  }, [token, url]);

  async function closeRep() {
    const clasedate = document.querySelector('#date').value;
    const amount = document.querySelector('#amount').value;

    if (amount === '') {
      setMessage('Please fill in petit cash');
      document.querySelector('#message').style.color = 'red';
    } else {
      setMessage('Please wait......');
      document.querySelector('#message').style.color = 'blue';
      try {
        const datass = JSON.stringify({
          clasedate,
          amount,
          userId: id,
        });
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${url}/add-petit-cash`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'Petit cash added successfully!') {
          document.querySelector('#date').value = '';
          document.querySelector('#amount').value = 0;
          setMessage(data.message);
          document.querySelector('#message').style.color = 'green';
          document.querySelector('#rep-date').innerHTML = amount;
        }
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        setMessage(`Reports could not be closed`);
        document.querySelector('#message').style.color = 'red';
      }
    }
  }
  // displaying the last date

  return (
    <>
      <div>
        <h5>
          Last added petit cash:
          {Object.keys(closeRepDataa).length !== 0 && (
            <span id="rep-date" style={{ color: 'green' }}>
              {closeRepDataa.lastPetitCash.map((row) => row.amount)}
              {Object.keys(closeRepDataa).length === 0 && (
                <span>Loading........</span>
              )}
            </span>
          )}{' '}
          Frw
        </h5>
      </div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': {
            m: 1,
            width: '30ch',
            marginTop: '20px',
          },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id="amount"
            label="Amount*"
            type="number"
            defaultValue="0"
          />
          <TextField id="date" type="date" defaultValue="" />
        </div>
        <p style={{ marginLeft: '15%' }} id="message">
          {message}
        </p>
        <div style={{ marginLeft: '20%', marginTop: '2%' }}>
          <Button variant="contained" onClick={closeRep}>
            Save
          </Button>
        </div>
      </Box>
    </>
  );
}

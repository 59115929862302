import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import '../css/Cars.css';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import axios from 'axios';
import { useUser, useToken } from '../components/auth';

export default function OwnerGaradataById() {
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const user = useUser();
  const { id } = user;
  const navigate = useNavigate();
  const { state } = useLocation();
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openPopup = () => {
    setOpen(true);
  };

  async function deleteData() {
    document.querySelector('#message').innerHTML = '';
    document.querySelector('#message').style.color = 'blue';
    try {
      document.querySelector('#message').innerHTML = 'Tegereza....';
      const datass = JSON.stringify({
        depense: state.row.id,
        user: id,
      });
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${url}/owner-garage/delete`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        data: datass,
      };

      const { data } = await axios.request(config);
      if (data.message === 'Removed') {
        navigate(-1);
        document.querySelector('#message').style.color = 'green';
      } else {
        document.querySelector('#message').innerHTML = data.message;
        document.querySelector('#message').style.color = 'red';
      }
    } catch (error) {
      console.log(error);
      document.querySelector('#message').innerHTML = 'Unable to delete data';
      document.querySelector('#message').style.color = 'red';
    }
  }

  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Garage" />
        </div>
        {/* displaying here */}
        <Box key={state.row.id} sx={{ flexGrow: 1, maxWidth: '90%' }}>
          <Typography
            style={{ display: 'flex', justifyContent: 'center' }}
            sx={{ mt: 4, mb: 2 }}
            variant="h4"
            component="div"
          >
            {state.row.plate} : {state.row.driver}
          </Typography>
          <Grid container spacing={2}>
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              item
              xs={12}
              md={6}
            >
              <Demo>
                <List dense={true}>
                  {/* <ListItem>
                    <ListItemText
                      primary={`Owner of the car: ${state.row.fname} ${state.row.lname}`}
                    />
                  </ListItem> */}
                  <ListItem>
                    <ListItemText primary={`Plate: ${state.row.plate}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Driver: ${state.row.driver}`} />
                  </ListItem>
                  <ListItem>
                    {/* <ListItemText primary={`Piece: ${state.row.piece}`} /> */}
                    <li
                      dangerouslySetInnerHTML={{
                        __html: `Piece: ${state.row.piece}`,
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Quantity: ${state.row.quantity}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Price: ${state.row.price}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Total: ${state.row.total}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={`Date: ${state.row.time
                        .toLocaleString('en-GB', {
                          timeZone: 'Africa/Kigali',
                        })
                        .substring(0, 10)}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`User: ${state.row.userdata}`} />
                  </ListItem>
                </List>
              </Demo>
            </Grid>
            <Grid item xs={12} md={6}>
              <Demo>
                {/* delete popup */}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle
                    style={{ cursor: 'move' }}
                    id="draggable-dialog-title"
                  >
                    Delete Data
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete this data?
                    </DialogContentText>
                    <p id="message"></p>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                      No
                    </Button>
                    <Button onClick={deleteData}>Yes</Button>
                  </DialogActions>
                </Dialog>
                {/* End of delete popup */}
                <List dense={true}>
                  <ListItem style={{ marginBottom: '30px' }}>
                    <Button
                      style={{ color: 'red' }}
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={openPopup}
                    >
                      Delete
                    </Button>
                  </ListItem>

                  <ListItem>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      startIcon={<ArrowBackIosIcon />}
                    >
                      Back
                    </Button>
                  </ListItem>
                </List>
              </Demo>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

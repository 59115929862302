import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/Addacgroup.css';
import { useUser } from '../components/auth';
import PetitCashDisplay from '../components/PetitCash';
import AddCash from '../components/AddCash';
import RefundDisplay from '../components/Refund';

export default function PetitCash() {
  const user = useUser();
  const { type } = user;
  // Getting existing users
  const defaultTab = type === 'users' ? 'reporting' : 'refund';

  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Petit Cash" />
        </div>
        {/* Tab here */}
        <br />

        <Tabs
          defaultActiveKey={defaultTab}
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="refund" title="Refund">
            <RefundDisplay />
          </Tab>

          <Tab eventKey="users" title="Report">
            <PetitCashDisplay />
          </Tab>

          <Tab eventKey="reporting" title="Add cash">
            <AddCash />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

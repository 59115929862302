import './css/App.css';
import SignIn from './pages/Login';
import Dashboard from './pages/Dashboard';
import Cars from './pages/Cars';
import OwnersPage from './pages/Owners';
import Acgroup from './pages/Acgroup';
import Acdatadispl from './pages/Acdata';
import { Routes, Route } from 'react-router-dom';
import { PrivatePages } from './components/privateRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import BusStops from './pages/BusStop';
import Garages from './pages/Garages';
import Users from './pages/Users';
import GaradataById from './pages/GarageData';
import GaledataById from './pages/GaleData';
import Fuel from './pages/Fuel';
import CarWash from './pages/CarWash';
import Parcels from './pages/Parcels';
import FueldataById from './pages/FuelData';
import ParceldataById from './pages/ParcelData';
import CarwwashdataById from './pages/CarwashData';
import Reports from './pages/reports';
import Expenses from './pages/Expenses';
import DepensesDataById from './pages/DepanseData';
import OtherDepensesDataById from './pages/DepanseData-other';
import Ration from './pages/Ration';
import RationDataById from './pages/RationData';
import UpdateProfileById from './pages/UpdateProfile';
import ChangePasswordById from './pages/ChangePassword';
import CarInfo from './pages/Carinfo';
import Drivers from './pages/Drivers';
import Workers from './pages/Workers';
import DriverDataById from './pages/DriverData';
import WorkerDataById from './pages/WorkerData';
import Setting from './pages/Settings';
import PetitCash from './pages/PetitCash';
import OtherExpenses from './pages/Expenses-others';
import RefundDataById from './pages/RefundData';
import GaragesOwners from './pages/GaragesOwners';
import OwnerGaradataById from './pages/GarageDataOwners';

function App() {
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <div>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route
            path="/dashboard"
            element={<PrivatePages path={<Dashboard />} />}
          />
          <Route path="/cars" element={<PrivatePages path={<Cars />} />} />
          <Route
            path="/owners/:owner"
            element={<PrivatePages path={<OwnersPage />} />}
          />
          <Route
            path="/ACgroup"
            element={<PrivatePages path={<Acgroup />} />}
          />
          <Route
            path="/data-by-ac/:owner/:sdate/:edate"
            element={<PrivatePages path={<Acdatadispl />} />}
          />
          <Route
            path="/bus-stops"
            element={<PrivatePages path={<BusStops />} />}
          />
          <Route path="/garage" element={<PrivatePages path={<Garages />} />} />
          <Route
            path="/owner-garage"
            element={<PrivatePages path={<GaragesOwners />} />}
          />
          <Route
            path="/garagedata"
            element={<PrivatePages path={<GaradataById />} />}
          />
          <Route
            path="/garagedataowners"
            element={<PrivatePages path={<OwnerGaradataById />} />}
          />
          <Route
            path="/galedata"
            element={<PrivatePages path={<GaledataById />} />}
          />
          <Route path="/fuel" element={<PrivatePages path={<Fuel />} />} />
          <Route
            path="/carwash"
            element={<PrivatePages path={<CarWash />} />}
          />
          <Route
            path="/parcels"
            element={<PrivatePages path={<Parcels />} />}
          />

          <Route
            path="/fueldata"
            element={<PrivatePages path={<FueldataById />} />}
          />

          <Route path="/spent" element={<PrivatePages path={<Expenses />} />} />
          <Route
            path="/other-spent"
            element={<PrivatePages path={<OtherExpenses />} />}
          />

          <Route
            path="depensedata"
            element={<PrivatePages path={<DepensesDataById />} />}
          />

          <Route
            path="otherdepensedata"
            element={<PrivatePages path={<OtherDepensesDataById />} />}
          />

          <Route
            path="/parceldata"
            element={<PrivatePages path={<ParceldataById />} />}
          />
          <Route
            path="carwashdata"
            element={<PrivatePages path={<CarwwashdataById />} />}
          />
          <Route path="/users" element={<PrivatePages path={<Users />} />} />
          <Route
            path="/reports"
            element={<PrivatePages path={<Reports />} />}
          />
          <Route path="/ration" element={<PrivatePages path={<Ration />} />} />
          <Route
            path="/rationdata"
            element={<PrivatePages path={<RationDataById />} />}
          />
          <Route
            path="/update-profile"
            element={<PrivatePages path={<UpdateProfileById />} />}
          />
          <Route
            path="/new-password"
            element={<PrivatePages path={<ChangePasswordById />} />}
          />
          <Route
            path="/carinfo"
            element={<PrivatePages path={<CarInfo />} />}
          />
          <Route
            path="/drivers"
            element={<PrivatePages path={<Drivers />} />}
          />

          <Route
            path="/driverdata"
            element={<PrivatePages path={<DriverDataById />} />}
          />

          <Route
            path="/workers"
            element={<PrivatePages path={<Workers />} />}
          />
          <Route
            path="/workerdata"
            element={<PrivatePages path={<WorkerDataById />} />}
          />
          <Route
            path="/settings"
            element={<PrivatePages path={<Setting />} />}
          />
          <Route
            path="/petit-cash"
            element={<PrivatePages path={<PetitCash />} />}
          />
          <Route
            path="/add-refund"
            element={<PrivatePages path={<RefundDataById />} />}
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;

import { useState, useEffect } from 'react';
import axios from 'axios';
import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/Addacgroup.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCars } from '../redux/cars/carSlice';
import { useUser, useToken } from '../components/auth';
import InfoIcon from '@mui/icons-material/Info';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router';
import moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';
import writeXlsxFile from 'write-excel-file';

export default function GaragesOwners() {
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const user = useUser();
  const { id, owner_id } = user;
  const { carData, isSuccess } = useSelector((state) => state.cars);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCars(token));
  }, [dispatch, token]);
  const [message, setMessage] = useState('');
  const [searchMessage, setSearchMessage] = useState('');
  const [plate, setPlate] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(0);
  const [userSearch, setUserSearch] = useState(0);
  const [userDataa, setUserDataa] = useState([]);

  useEffect(() => {
    async function locationsList() {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/cars/drivers`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setDrivers(data);
      } catch (error) {
        console.log(error);
      }
    }
    locationsList();
  }, [token, url]);
  useEffect(() => {
    const userData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/signup`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setUserDataa(data.getUser.filter((loc) => loc.type === 'Garage'));
      } catch (error) {
        console.log(error);
      }
    };
    userData();
  }, [token, url]);

  function handleSaerchUser(event) {
    setUserSearch(event.target.value);
  }
  // Getting existing users
  const [garageDataa, setGarageDataa] = useState([]);
  useEffect(() => {
    const userData = async () => {
      const time = moment().format().substring(0, 10);
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/garagesdataowners?sdate=${time}&edate=${time}&user=0`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setGarageDataa(data);
      } catch (error) {
        console.log(error);
      }
    };
    userData();
  }, [token, url]);

  // searching

  async function search() {
    setGarageDataa([]);
    const sdate = document.querySelector('#s-date').value;
    const edate = document.querySelector('#e-date').value;
    if (sdate === '' || edate === '') {
      setSearchMessage('Please select start date and end date');
      document.querySelector('#searchMessage').style.color = 'red';
    } else {
      setSearchMessage('Tegereza.....');
      document.querySelector('#searchMessage').style.color = 'blue';

      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/garagesdataowners?sdate=${sdate}&edate=${edate}&user=${userSearch}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setGarageDataa(data);
        if (data.garagesData.length !== 0) {
          setSearchMessage('');
        } else {
          setSearchMessage(`No data found from ${sdate} to ${edate}`);
          document.querySelector('#searchMessage').style.color = 'green';
        }
      } catch (error) {
        console.log(error);
        setSearchMessage('Error while retrieving data');
        document.querySelector('#searchMessage').style.color = 'red';
      }
    }
  }

  // Adding garages data
  function handleChanges(event) {
    setPlate(event.target.value);
  }

  function handleDriverChanges(event) {
    setDriver(event.target.value);
  }

  async function saveGarage() {
    const datee = document.querySelector('#date').value;
    const piece = document.querySelector('#piece').value;
    const price = document.querySelector('#price').value;
    const quantity = document.querySelector('#quantity').value;
    const total = document.querySelector('#total').value;

    if (
      plate === '' ||
      driver === '' ||
      driver === 0 ||
      piece === '' ||
      price === '' ||
      quantity === '' ||
      total === ''
    ) {
      setMessage('Please fill out all the required fields');
    } else {
      try {
        const datass = JSON.stringify({
          plate: plate,
          datee: datee,
          driver: driver,
          piece: piece,
          price: price,
          quantity: quantity,
          total: total,
          user: id,
          owner: owner_id,
        });
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${url}/addgarageowners`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'Garage data added') {
          document.querySelector('#date').value = '';
          document.querySelector('#piece').value = '';
          document.querySelector('#price').value = '';
          document.querySelector('#quantity').value = '';
          document.querySelector('#total').value = '';
          setPlate('');
          setDriver(0);
          setMessage(data.message);
        }
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        setMessage(
          `<span style='color: red;'>Garage data could not be added</span>`
        );
      }
    }
  }

  // displaying garages datas

  //totals

  let total = 0;
  let displayData = [];

  if (Object.keys(garageDataa).length !== 0) {
    let i = 0;
    displayData = garageDataa.garagesData.map((row) => {
      i++;
      total += parseInt(row.total);
      const ti = row.time
        .toLocaleString('en-GB', {
          timeZone: 'Africa/Kigali',
        })
        .substring(0, 10);

      return { ...row, time: ti, i };
    });
  }
  // preparing data for export

  const HEADER_ROW = [
    {
      value: 'No',
      fontWeight: 'bold',
    },
    {
      value: 'Plate',
      fontWeight: 'bold',
    },

    {
      value: 'Piece',
      fontWeight: 'bold',
    },
    {
      value: 'Quantity',
      fontWeight: 'bold',
    },
    {
      value: 'Price',
      fontWeight: 'bold',
    },
    {
      value: 'Total',
      fontWeight: 'bold',
    },
    {
      value: 'User',
      fontWeight: 'bold',
    },
    {
      value: 'Date',
      fontWeight: 'bold',
    },
  ];
  //datas
  let rowss = [];
  rowss = displayData.map((row) => {
    return [
      {
        type: Number,
        value: row.i,
      },
      {
        value: row.plate,
      },
      {
        value: row.piece,
      },
      {
        value: row.quantity,
      },
      {
        value: row.price,
      },
      {
        value: row.total,
      },
      {
        value: row.userdata,
      },
      {
        value: row.time,
      },
    ];
  });
  //totals
  const TOTAL_ROW = [
    {
      value: 'TOTAL',
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: total,
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
  ];

  const data = [HEADER_ROW, ...rowss, TOTAL_ROW];
  async function exportData(d) {
    await writeXlsxFile(d, {
      fileName: 'Garages.xlsx',
    });
  }

  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Garages" />
        </div>
        {/* Tab here */}
        <br />

        <Tabs
          defaultActiveKey="garages"
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="garages" title="Garage data">
            <div className="cars-add-btn-dv">
              <FormControl sx={{ m: 1, width: '20%', marginTop: 2.5 }}>
                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                <Select
                  id="location_search"
                  label="Location*"
                  value={userSearch}
                  onChange={handleSaerchUser}
                >
                  <MenuItem key={0} value={0}>
                    All locations
                  </MenuItem>
                  {userDataa.length !== 0 &&
                    userDataa.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.fname} {row.lname} - {row.location_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <input
                style={{ height: '40px', marginTop: '30px' }}
                name="s-date"
                id="s-date"
                type="date"
              />
              <span style={{ width: '30px' }}> </span>
              <input
                style={{ height: '40px', marginTop: '30px' }}
                name="e-date"
                id="e-date"
                type="date"
              />
              <span style={{ width: '10px' }}> </span>
              <Button
                style={{ height: '40px', marginTop: '30px' }}
                onClick={search}
                variant="contained"
              >
                SEARCH
              </Button>
            </div>
            <div style={{ marginLeft: '20px' }}>
              <p id="searchMessage">{searchMessage}</p>
            </div>

            {Object.keys(garageDataa).length !== 0 && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead style={{ background: 'black' }}>
                    <TableRow className="">
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>No</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Plate</h6>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <h6 style={{ color: '#fff' }}>Piece</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Quantity</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Price</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Total</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>User</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Date</h6>
                      </TableCell>
                      <TableCell>
                        <h6>More</h6>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {displayData.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.i}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.plate}
                        </TableCell>
                        <TableCell
                          dangerouslySetInnerHTML={{
                            __html: row.piece,
                          }}
                        />
                        <TableCell>{row.quantity}</TableCell>
                        <TableCell>{row.price}</TableCell>
                        <TableCell>{row.total}</TableCell>
                        <TableCell>{row.userdata}</TableCell>
                        <TableCell>
                          {row.time
                            .toLocaleString('en-GB', {
                              timeZone: 'Africa/Kigali',
                            })
                            .substring(0, 10)}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              navigate('/garagedataowners', { state: { row } })
                            }
                            startIcon={<InfoIcon />}
                          >
                            More
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell>Total</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {new Intl.NumberFormat().format(total)}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>

                      <TableCell></TableCell>
                    </TableRow>
                    {Object.keys(garageDataa).length === 0 && (
                      <p>Loading........</p>
                    )}
                  </TableBody>
                </Table>
                <Button
                  variant="outlined"
                  onClick={() => exportData(data)}
                  startIcon={<GetAppIcon />}
                >
                  Export data
                </Button>
              </TableContainer>
            )}
          </Tab>
          <Tab eventKey="add" title="Add garage data">
            {/*Add user here */}
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': {
                  m: 1,
                  width: '30ch',
                  marginTop: '20px',
                },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <FormControl sx={{ m: 1, minWidth: 300, marginTop: 2.5 }}>
                  <InputLabel id="demo-simple-select-label">Plate*</InputLabel>
                  <Select
                    id="plate"
                    label="Plate*"
                    value={plate}
                    onChange={handleChanges}
                  >
                    {isSuccess &&
                      carData.carsData.map((car) => (
                        <MenuItem key={car.id} value={car.id}>
                          {car.plate}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <TextField id="date" type="date" defaultValue="" />
              </div>
              <div>
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 300,
                    marginTop: 2.5,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Driver*</InputLabel>
                  <Select
                    id="driver"
                    label="Driver*"
                    value={driver}
                    onChange={handleDriverChanges}
                  >
                    <MenuItem key={0} value={0}>
                      Select a driver
                    </MenuItem>
                    {drivers.length !== 0 &&
                      drivers.driversData.map((loc) => (
                        <MenuItem key={loc.id} value={loc.id}>
                          {loc.names}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <TextField id="piece" label="Piece*" defaultValue="" />
              </div>
              <div>
                <TextField
                  id="price"
                  label="Price*"
                  type="number"
                  defaultValue=""
                />
                <TextField
                  id="quantity"
                  label="Quantity*"
                  type="number"
                  defaultValue=""
                />
              </div>

              <div>
                <TextField
                  id="total"
                  type="number"
                  label="Total*"
                  defaultValue=""
                />
                {/* selectable */}

                {/* ens */}
              </div>
              <p style={{ marginLeft: '15%' }}>{message}</p>
              <div style={{ marginLeft: '20%', marginTop: '2%' }}>
                <Button variant="contained" onClick={saveGarage}>
                  Save
                </Button>
              </div>
            </Box>

            {/* End of add a user */}
          </Tab>
        </Tabs>
        {/* Tabs ends here */}
      </div>
    </div>
  );
}
